<template>
  <div class="form__group">
    <input
      class="form__control"
      v-model="value"
      :placeholder="placeholder"
      type="text"
      @input="onValueChange"
      :maxLength="length"
    />
    <span class="form__line" />
  </div>
</template>

<script>
export default {
  name: "CustomInput",
  props: ["changedValue", "placeholder", "length"],
  data: function () {
    return {
      value: this.changedValue,
    };
  },
  methods: {
    onValueChange: function () {
      this.$emit("value-change", this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.form__group {
  position: relative;

  width: 100%;
}

.form__control {
  display: block;

  width: 100%;
  padding: 0.5rem 0 0.75rem 0.2rem;
  border: none;
  border-bottom: 2px solid #ffffff;
  border-radius: 0;
  box-shadow: none;

  font-family: "RobotoRegular", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;

  color: #fff;
  background: none;

  &::placeholder {
    color: #fff;
  }

  &:focus + .form__line {
    width: 100%;
  }
}

.form__line {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  width: 0;
  height: 2px;

  background-color: #faac18;

  transition: width 0.2s linear;
}

.invalid {
  & .form__control {
    border-bottom: 2px solid #f44336;
  }

  & .form__line {
    background-color: #f44336;
  }
}

@media (max-width: 1440px) {
  .form__control {
    font-size: 2.25rem;
  }
}

@media (max-width: 991px) {
  .form__control {
    font-size: 1.2rem;
  }
}
</style>
