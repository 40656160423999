<template>
  <main class="home">
    <BlurEffect class="blur--width" />
    <div class="home__inner">
      <div class="container">
        <header class="home__header">
          <img class="home__icon" :src="logo" alt="Pursuit and profit" />
        </header>
        <section class="home__content">
          <div class="home__text">
            Test your knowledge of OPERATIONAL SHRINK principles in Profit
            Pursuit! Answer questions in each of <span>3</span> shrink
            categories to fill your scoring token. In the final round, you'll
            compete against your fellow SMs for a chance to win a prize!
          </div>
          <form class="form">
            <div class="form__row">
              <CustomInput
                placeholder="Name"
                :changedValue="name"
                v-on:value-change="changeName"
                :class="{
                  invalid: $v.name.$dirty && !$v.name.required,
                }"
              />
              <small
                class="helper-text invalid"
                v-if="$v.name.$dirty && !$v.name.required"
                >Please enter your name</small
              >
            </div>
            <div class="form__row">
              <CustomInput
                placeholder="4-Digit Store Number"
                :changedValue="store"
                @value-change="changeStore"
                length="4"
                :class="{
                  invalid: ($v.store.$dirty && !$v.store.required) || error,
                }"
              />
              <small
                class="helper-text invalid"
                v-if="($v.store.$dirty && !$v.store.required) || error"
                >Enter a valid store number</small
              >
            </div>
            <div class="form__row">
              <SubmitButton
                @action="submitHandler"
                size="md"
                text="Let's Play!"
                variant="contained"
              />
            </div>
          </form>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import SubmitButton from "@/components/SubmitButton";
import CustomInput from "@/components/CustomInput";
import BlurEffect from "@/components/BlurEffect";
import routeNames from "@/router/routeNames";
import logo from "../assets/images/icons/logo-with-hand.svg";

export default {
  name: "Home",
  components: { BlurEffect, SubmitButton, CustomInput },
  data: () => ({
    name: "",
    store: "",
    logo,
  }),
  computed: {
    error() {
      return this.$store.getters.error;
    },
  },
  validations: {
    name: { required },
    store: { required },
  },
  async mounted() {
    this.error && (await this.$store.dispatch("clearError"));
  },
  methods: {
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      try {
        const user = await this.$store.dispatch("validateUser", this.store);
        await this.$store.dispatch("setUser", {
          ...user,
          name: this.name,
          score: 0,
        });
        localStorage.setItem("userId", user._id);
        await this.$router.push(routeNames.selection);
      } catch (error) {
        console.log(error);
      }
    },
    changeName(value) {
      this.name = value;
    },
    changeStore(value) {
      this.store = value;
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  height: 1px;
}

.home__inner {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 79.16%;
  height: 74.27%;
  padding-top: 9.8rem;
  border-radius: 37.3rem;
  border-width: 7px;
  border-style: dotted;
  border-right-color: #faac18;
  border-top-color: #fa8918;
  border-left-color: #f15a24;
  border-bottom-color: rgba(239, 115, 38, 0.98);
}

.blur--width {
  width: 79.16% !important;
  height: 74.27% !important;
}

.container {
  width: 100%;
  max-width: 74.3rem;
  padding-inline: 1.5rem;
  margin-inline: auto;
}

.home__header {
  margin-bottom: 8.8rem;
}

.home__icon {
  display: block;

  max-width: 100%;
  height: auto;
  margin-inline: auto;
}

.home__content {
  display: flex;
  justify-content: space-between;

  max-width: 61.4rem;
  margin-inline: auto;
}

.home__text {
  max-width: 32rem;

  font-family: "RosarioBold", sans-serif;
  font-size: 1.8rem;
  line-height: 1.44;
  font-weight: 700;
  text-align: left;
}

.form {
  display: flex;
  flex-direction: column;

  max-width: 21.2rem;
  width: 100%;
}

.form__row {
  position: relative;

  display: flex;

  &:first-child {
    margin-bottom: 2.8rem;
  }

  &:last-child {
    margin-top: 5.8rem;
  }

  & > button {
    max-width: 21.1rem;
    width: 100%;
  }
}

.helper-text {
  position: absolute;
  left: 0;
  bottom: -2rem;

  font-family: "RosarioRegular", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
}

.invalid {
  color: #f44336;
}

@media (max-width: 1440px) {
  .home__inner {
    padding-top: 12.25rem;
    border-radius: 46.625rem;
  }

  .container {
    max-width: 77rem;
  }

  .home__header {
    margin-bottom: 10rem;
  }

  .home__content {
    max-width: 76.75rem;
  }

  .home__text {
    max-width: 40rem;
    font-size: 2.25rem;
  }

  .form {
    max-width: 26.5rem;
  }

  .form__row {
    &:first-child {
      margin-bottom: 3.5rem;
    }

    &:last-child {
      margin-top: 7.25rem;
    }

    & > button {
      max-width: 26.375rem;
    }
  }

  .helper-text {
    font-size: 1.5rem;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 70rem;
  }

  .home__inner {
    padding-top: 8rem;
  }

  .home__text {
    max-width: 32.5rem;

    font-size: 1.875rem;
  }

  .home__icon {
    max-width: 60rem;
  }

  .helper-text {
    bottom: -2rem;

    font-size: 1.375rem;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 70rem;
    padding: 2.5rem;
  }

  .home__inner {
    padding-top: 0;
  }

  .home__header {
    margin-bottom: 3rem;
  }

  .home__icon {
    max-width: 37.5rem;
  }

  .home__content {
    max-width: 55rem;
  }

  .home__text {
    max-width: 32.5rem;

    font-size: 1.5rem;
  }

  .form {
    max-width: 20rem;
  }

  .form__row {
    &:first-child {
      margin-bottom: 1.25rem;
    }

    &:last-child {
      margin-top: 2.8rem;
    }

    & > button {
      max-width: 20rem;
    }
  }

  .helper-text {
    bottom: -1.5rem;

    font-size: 1rem;
  }
}

@media (max-width: 668px) {
  .helper-text {
    font-size: 0.75rem;
  }

  .form__row {
    & > button {
      max-width: 15rem;
    }
  }
}
</style>
